import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IUserAvatar, UserAvatarIconComponent } from '@aston/user-badge';


@Component({
    selector: 'user-avatar',
    template: `
		<user-avatar-icon
			[user]="user()"
			[hasFullName]="hasFullName()"
			[isSystem]="isSystem()"
		/>
	`,
    imports: [TranslateModule, NgbTooltipModule, UserAvatarIconComponent]
})
export class UserAvatarComponent {
	hasFullName = input<boolean>(false);
	isSystem = input<boolean>(false);
	user = input<IUserAvatar>();
}
