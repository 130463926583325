import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { fr as dateLocaleFr } from 'date-fns/locale/fr';
import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Store } from '@ngrx/store';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppBaseConfiguration, AppConstants, AppMissingTranslationHandler, FactorConfigurationService, LoaderSpinnerComponent, NotificationMessageComponent, PageAnchorsComponent, PopoverComponent, TelemetryInitializer, ValidationService, registerDateLocale } from '@aston/foundation';
import { CTX_HELP_CONNECTOR, ContextHelpModule } from '@aston/context-help';
import { provideFoundationServices } from '@aston/foundation/provider-bundles';
import { BusinessThemeService, CSSThemeService, DefaultBusinessThemeService, ThemesModule } from '@aston/themes';
import { userPrefsFiltersConnector } from '@aston/user-prefs';
import { USER_FILTERS_CONNECTOR } from '@aston/filters';
import { provideNgxMask } from 'ngx-mask';
import { RouterModule, provideRouter, withViewTransitions } from '@angular/router';
import { AbstractSecurityStorage, AuthModule, StsConfigLoader } from 'angular-auth-oidc-client';
import { filter } from 'rxjs';

import themes from '../assets/sass/themes';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfiguration } from './app.configuration';
import { RootStoreModule } from './root-store';
import { AppStoreActions, AppStoreSelectors } from './root-store/app-store';
import { AppConstants as FederationAppConstants } from './app.constants';
import { currentTenantId } from './authentication-module/functions';
import { FederationTelemetryInitializer, FederationValidationService } from './shared-module/services';
import { AuthenticationStorageService } from './authentication-module/services';
import { AuthenticationInterceptor } from './authentication-module/interceptors';
import { configureAuth } from './app.auth';
import { FederationFactorConfigurationService } from './shared-module/services/factor-configuration.service';
import { translateLoader } from './app.translate';
import { ctxHelpConnector, onViewTransitionCreated, provideDebugBar } from './app.connectors';



// override constants with our own
AppConstants.overrideWith(FederationAppConstants);

registerDateLocale('fr', dateLocaleFr);

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent],
	imports: [
		PopoverComponent, // wtf workaround: force some ngb-backed components to appear first in bundles
		LoaderSpinnerComponent,
		NotificationMessageComponent,
		PageAnchorsComponent,
		RootStoreModule,
		RouterModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		TranslateModule.forRoot({
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: AppMissingTranslationHandler
			},
			loader: {
				provide: TranslateLoader,
				useFactory: (translateLoader),
				deps: [HttpClient, AppConfiguration]
			}
		}),
		ThemesModule.forRoot({
			themes,
			cssThemeService: {
				provide: CSSThemeService,
				useClass: CSSThemeService
			},
			businessThemeService: {
				provide: BusinessThemeService,
				useClass: DefaultBusinessThemeService
			},
		}),
		AuthModule.forRoot({
			loader: {
				provide: StsConfigLoader,
				useFactory: configureAuth,
				deps: [AppConfiguration],
			},
		}),
		ContextHelpModule,
	],
	providers: [
		{
			provide: ValidationService,
			useClass: FederationValidationService
		},
		{
			provide: FactorConfigurationService,
			useClass: FederationFactorConfigurationService
		},
		AppConfiguration,
		{
			provide: AppBaseConfiguration,
			useExisting: AppConfiguration
		},
		{
			provide: BusinessThemeService,
			useClass: DefaultBusinessThemeService
		},
		{
			provide: TelemetryInitializer,
			useClass: FederationTelemetryInitializer,
		},
		provideAppInitializer(() => {
        const initializerFn = (AppConfigurationFactory)(inject(Store));
        return initializerFn();
      }),
		{
			provide: USER_FILTERS_CONNECTOR,
			useFactory: userPrefsFiltersConnector,
			deps: [Store],
		},
		{
			provide: APP_BASE_HREF,
			useFactory: getBaseLocation
		},
		{ provide: AbstractSecurityStorage, useClass: AuthenticationStorageService },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
		{ provide: CTX_HELP_CONNECTOR, useFactory: ctxHelpConnector, deps: [Store] },
		provideRouter([], withViewTransitions({ skipInitialTransition: true, onViewTransitionCreated })),
		provideHttpClient(withInterceptorsFromDi()),
		provideFoundationServices(),
		provideDebugBar(),
		provideNgxMask(),
	]
})
export class AppModule {
}

export function getBaseLocation() {
	return `/${currentTenantId()||''}`;
}

export function AppConfigurationFactory(store: Store): CallableFunction {
	return () => new Promise(resolve => {
		store.dispatch(AppStoreActions.StartInitializer());
		store.select(AppStoreSelectors.selectIsInitFinished).pipe(filter(ready => ready)).subscribe(_ => {
			resolve(true);
		});
	});
}
