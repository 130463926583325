import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PageHeaderComponent } from '../page-header/page-header.component';

@Component({
    templateUrl: './not-found-page.component.html',
    imports: [NgIf, PageHeaderComponent, TranslateModule]
})
export class NotFoundPageComponent implements OnInit {
    message = '';

    constructor(private router: Router) {}

    ngOnInit(): void {
        // lastSuccessfulNavigation is not exposed publicly yet
        // https://github.com/angular/angular/pull/27198#issuecomment-475332896
        const ctx = this.router['lastSuccessfulNavigation']?.extras?.state || { context: '' };
        this.message = ctx['context'].join('');
    }
}
