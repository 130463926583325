import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgFor } from '@angular/common';

import { ScrollToAnchorDirective } from '../../directives/anchors/scroll-to-anchor.directive';
import { IPageAnchor } from '../../models';


@Component({
    selector: 'page-anchors',
    templateUrl: './page-anchors.component.html',
    imports: [NgIf, NgFor, ScrollToAnchorDirective, NgbTooltipModule, TranslateModule]
})
export class PageAnchorsComponent {
	@Input() anchors: IPageAnchor[] = [];
}
