import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'authentication-layout',
    templateUrl: './authentication-layout.component.html',
    imports: [RouterOutlet, TranslateModule]
})
export class AuthenticationLayoutComponent {

}
