
import { Component, Output, EventEmitter } from '@angular/core';
import { BtnGroupSwitchComponent, IAction, StoreLoader } from '@aston/foundation';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NotificationsStoreActions, NotificationsStoreSelectors } from 'apps/federation/src/app/root-store/notifications-store';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { INotificationItem, INotificationsList } from '../../models';
import { NotificationsListComponent } from '../notifications-list/notifications-list.component';

@Component({
    selector: 'notifications-panel',
    templateUrl: './notifications-panel.component.html',
    imports: [AsyncPipe, TranslateModule, NotificationsListComponent, BtnGroupSwitchComponent]
})
export class NotificationsPanelComponent {
	@Output() action: EventEmitter<IAction> = new EventEmitter();

	list$: Observable<INotificationsList> = this.store.select(NotificationsStoreSelectors.selectNotificationsForSidebar);
	count$: Observable<number> = this.store.select(NotificationsStoreSelectors.selectAllCount);
	hasUnread$: Observable<boolean> = this.store.select(NotificationsStoreSelectors.selectHasUnread);
	showUnreadOnly$: Observable<boolean> = this.store.select(NotificationsStoreSelectors.selectShowUnreadOnly);

	loader: StoreLoader = new StoreLoader(
		this.store.select(NotificationsStoreSelectors.selectNotificationsIsLoading),
		this.store.select(NotificationsStoreSelectors.selectNotificationsError)
	);

	constructor(private store: Store) { }

	close() {
		this.store.dispatch(NotificationsStoreActions.ClosePanel());
	}

	open(notification: INotificationItem) {
		this.store.dispatch(NotificationsStoreActions.OpenNotification({ notification }));
	}

	toggleMode(unreadOnly: boolean) {
		if (unreadOnly) {
			this.store.dispatch(NotificationsStoreActions.ShowUnreadOnly());
		} else {
			this.store.dispatch(NotificationsStoreActions.ShowAll());
		}
	}
}
