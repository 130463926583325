import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { SafePipe } from '../../pipes/safe.pipe';
import { NotificationType } from '../../enums';
import { INotification } from '../../models';


@Component({
    selector: 'notification-message',
    templateUrl: './notification-message.component.html',
    imports: [NgbAlertModule, TranslateModule, SafePipe]
})
export class NotificationMessageComponent implements OnInit {
	@Input() notification: INotification;
	@Input() autoCloseDelay = 3000;
	@Input() dismissDelay = 300;
	@Output() dismissEvent: EventEmitter<void> = new EventEmitter();

	type: string;
	animatingClass = '';

	ngOnInit() {
		if (!!this.notification.options && this.notification.options.autoCloseDelay) {
			this.autoCloseDelay = this.notification.options.autoCloseDelay;
		}

		switch (this.notification.type) {
			case NotificationType.ERROR:
				this.type = 'danger';
				break;
			case NotificationType.SUCCESS:
				this.type = 'success';
				break;
			case NotificationType.WARNING:
				this.type = 'warning';
				break;
		}

		// add a class for the animation of the alert
		window.setTimeout(() => {
			this.animatingClass = 'in';
		}, 100);

		if (this.notification.autoClose) {
			window.setTimeout(() => {
				this.dismiss();
			}, this.autoCloseDelay);
		}
	}

	dismiss() {
		this.animatingClass = '';

		window.setTimeout(() => {
			this.dismissEvent.emit();
		}, this.dismissDelay);
	}
}
