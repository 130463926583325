<div class="version-info-container d-flex justify-content-between" *ngIf="!hidden()">

  <div class="text-right d-sm-none">
    @if (errors$ | async; as errors) {
      <i class="fas fa-exclamation-triangle text-danger"></i> {{errors}}
    }
  </div>

  <div class="text-left d-sm-none">
    @if (tenantDesc$ | async; as tenant) {
      <span>T {{tenant}}</span>
    }
    @if (ws) {
      &nbsp;|&nbsp; <i class="fa"
         [class.fa-wifi]="ws.connected$ | async"
         [class.fa-wifi-slash]="(ws.connected$ | async) === false"
      ></i>
    }
  </div>

  <div class="text-left d-none d-sm-block">
    {{ configuration.local ? scrolled() : '' }}

    @if (errors$ | async; as errors) {
      &nbsp;|&nbsp; <i class="fas fa-exclamation-triangle text-danger"></i> Inspect {{errors}} errors
    }

  </div>

  <div class="text-right d-none d-sm-block">
    {{configuration.version}}.{{configuration.buildMetadata}}

    &nbsp;|&nbsp; {{configuration.name}}

    &nbsp;|&nbsp; @if (tenantDesc$ | async; as tenant) {
      <span class="cursor-pointer" (click)="clipboard.writeText(tenant)">Tenant {{tenant}}</span>
    }

    @if (timezone) {
      &nbsp;|&nbsp; <span [class.text-danger]="diffTimezone" [title]="timezone">{{msTimezone}}</span>
      <div ngbDropdown placement="top-right">
        <span ngbDropdownToggle></span><div class="dropdown-menu timezones-menu" ngbDropdownMenu>
          <span ngbDropdownItem *ngFor="let t of MS_TO_IANA" (click)="useDefaultTimezone(t.ms)">{{t.ms}} ({{t.iana}})</span>
        </div>
      </div>
    }

    &nbsp;|&nbsp; <a *ngIf="sessionId$ | async as session" [href]="session">id</a>

    @if (inactivityService) {
      &nbsp;|&nbsp; <span *ngIf="tokenExpiration$ | async as tokenExpiration; else loggedout"
          [class.alert-danger]="inGracefulPeriod$ | async" title="Token renew time (-renew before)">
          token renew at {{tokenExpiration$ | async | dateFormat: 'JustTime'}} -{{tokenTimeout$ | async }}s
        </span>
        <ng-template #loggedout><span class="alert-danger">logged out</span></ng-template>

      &nbsp;|&nbsp; <span title="Considered inactive in">inactive in {{inactivityCountdown$|async}}s</span>
    }

    @if (ws) {
      &nbsp;|&nbsp; <i title="Websocket {{ ws.hubId$ | async }} (click to copy)" 
        (click)="clipboardHubId()" class="fa cursor-pointer"
        [class.fa-wifi]="ws.connected$ | async"
        [class.fa-wifi-slash]="(ws.connected$ | async) === false"
      ></i>
    }

    @if (ffs) {
      &nbsp;|&nbsp; <div ngbDropdown placement="top-right">
        <span ngbDropdownToggle data-cy="DropdownToggle">⌥</span><div class="dropdown-menu feature-flags-menu" ngbDropdownMenu>
          <span ngbDropdownItem *ngFor="let f of featureFlags" (click)="toggleFeature(f)">{{startCase(f)}} <span *ngIf="ffs.isFeatureEnabled(f)">✓</span></span>
          <span ngbDropdownItem *ngIf="supportImpersonation" (click)="supportImpersonation()">Impersonate support</span>
        </div>
     </div>
    }

  </div>

</div>
