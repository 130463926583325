
import { Component, Input } from '@angular/core';
import { INotificationItem } from 'apps/federation/src/app/shared-module/models';
import { DateFormatPipe } from '@aston/foundation';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationCategory } from '../../enums';
import { NotificationStatusPipe } from '../../pipes';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';

@Component({
    selector: 'notification-item',
    templateUrl: './notification-item.component.html',
    imports: [TranslateModule, UserAvatarComponent, DateFormatPipe, NotificationStatusPipe]
})
export class NotificationItemComponent {
	@Input() notification: INotificationItem;

	notificationsCategories = NotificationCategory;

	get isSystemUser(): boolean {
		return this.notification.category === NotificationCategory.None;
	}
}
