
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Loader, LoaderSpinnerComponent, UnsubscriberClass } from '@aston/foundation';
import { FormsModule } from '@angular/forms';

import { AuthenticationService } from '../../services';

@Component({
    selector: 'login-page',
    templateUrl: './login-page.component.html',
    imports: [FormsModule, LoaderSpinnerComponent]
})
export class LoginPageComponent extends UnsubscriberClass implements OnInit {

	formLoader = new Loader();
	redirectAlreadyDone: boolean;

	constructor(
		protected authenticationService: AuthenticationService,
		protected activatedRoute: ActivatedRoute,
		protected router: Router) {
		super();
	}

	ngOnInit() {
		this.authenticationService.login();
	}
}
